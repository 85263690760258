<template>
  <v-content>
    <router-view />
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
    },
  }
</script>

<style>
* {
  align-content: center;
  justify-content: center;
}
</style>
